import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Header from "./components/header/Header";
import Home from "./components/Home";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    }
]);

function App() {
    return (
        <RouterProvider router={router}/>
    );
}

export default App;
