import React from "react";
import {MapPin, Mail, BrandFacebook, Phone} from 'tabler-icons-react';

const Contact = () => {
    return (

        <div className="contact">
            <div className={"contact-grid"}>
                <div className={"contact-item"}>
                    <div className={"circle"}>
                        <MapPin size={35} stroke-width="1.25"/>
                    </div>
                    <span>
                        ul. Styki 11a<br/>
                        Saska Kępa<br/>
                        Warszawa, 03-928
                    </span>
                </div>
                <div className={"contact-item"}>
                    <div className={"circle"}>
                        <Phone size={35} stroke-width="1.25"/>
                    </div>
                    <span>
                        +48 692 493 230<br/>
                        +48 696 818 344<br/>
                        Pracujemy w godzinach:<br/>
                        pn. - pt. 12:00 - 18:00<br/>
                        sob. 10:00 - 13:00<br/>
                    </span>
                </div>
                <div className={"contact-item"}>
                    <div className={"circle"}>
                        <Mail size={35} stroke-width="1.25"/>
                    </div>
                    <span>
                        info@bicykleta.pl
                    </span>
                </div>
                <div className={"contact-item"}>
                    <div className={"circle"}>
                        <BrandFacebook size={35} stroke-width="1.25"
                                       onClick={(e) => {
                                           e.preventDefault();
                                           window.location.href = "https://www.facebook.com/profile.php?id=100066722863012"
                                       }}/>
                    </div>
                    <span>
                        Facebook
                    </span>
                </div>
            </div>
        </div>

    )
}

export default Contact;
















