import React from "react";

const Map = () => {
    return (

        <div className="map">
            <iframe className={"map-widget"} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?hl=en&amp;q=Jana%20Styki%2011%20Warsaw+(Bicykleta)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
        </div>

    )
}

export default Map;
















