import React, {useState} from "react";
import "../../index.css";

const Menu = ({scrollTo, pricingRef, contactRef}) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    function refreshPage() {
        window.location.reload();
    }

    return (<div>

            <div className={"menu" + (menuOpen ? " open" : "")}>
                <img src={process.env.PUBLIC_URL + "/img/logo.png"} alt={"logo"} className={"logo"}/>
                <ul className={"menu-list " + (menuOpen ? "open" : "")}>
                    <li>
                        <button onClick={refreshPage}>
                            WITAJ
                        </button>
                    </li>
                    <li>
                        <button onClick={() => scrollTo(pricingRef)}>
                            CENNIK
                        </button>
                    </li>
                    <li>
                        <button onClick={() => scrollTo(contactRef)}>
                            KONTAKT
                        </button>
                    </li>
                </ul>
                <div id="menu-btn" onClick={toggleMenu} className={menuOpen ? "open" : ""}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>

    )
}

export default Menu;
















