import React from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Autoplay, A11y} from 'swiper';
import 'swiper/css';
import "../../index.css";

const Carousel = () => {
    return (

        <Swiper
            slidesPerView={1}
            onSlideChange={() => console.log('slide change')}
            modules={[Navigation, Autoplay, A11y]}
            navigation={{enabled: true}}
            autoplay={{delay: 3000}}
        >
            <SwiperSlide className={"swiper-slide"}>
                <img className={"swiper-img"} src={process.env.PUBLIC_URL + "/img/img1.png"} alt={"img1"}/>
            </SwiperSlide>
            <SwiperSlide className={"swiper-slide"}>
                <img className={"swiper-img"} src={process.env.PUBLIC_URL + "/img/img2.png"} alt={"img2"}/>
            </SwiperSlide>
            <SwiperSlide className={"swiper-slide"}>
                <img className={"swiper-img"} src={process.env.PUBLIC_URL + "/img/img3.png"} alt={"img3"}/>
            </SwiperSlide>
        </Swiper>);
};

export default Carousel;
















