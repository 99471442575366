import React from "react";
import Carousel from "./Carousel";
import Menu from "./Menu";
import "../../index.css";

const Header = ({scrollTo, contactRef, pricingRef}) => {
    return (

        <div>
            <Carousel className={"carousel"}/>
            <Menu scrollTo={scrollTo} pricingRef={pricingRef} contactRef={contactRef}/>
        </div>

    )
}

export default Header;
















