import React from "react";
import Contact from "./Contact";
import Map from "./Map";

const Footer = () => {
    return (

        <div className="footer">
            <Contact/>
            <Map/>
        </div>

    )
}

export default Footer;
















