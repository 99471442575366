import React, {useRef} from "react";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import Pricing from "./pricing/Pricing";

const Home = () => {
    const pricingRef = useRef(null);
    const contactRef = useRef(null);

    const scrollTo = (elementRef) => {
        if (elementRef && elementRef.current) {
            window.scrollTo({
                top: elementRef.current.offsetTop, behavior: "smooth"
            });
        }
    }

    return (

        <div className={"main"}>
            <Header scrollTo={scrollTo} pricingRef={pricingRef} contactRef={contactRef}/>
            <section ref={pricingRef}>
                <Pricing/>
            </section>
            <section ref={contactRef}>
                <Footer/>
            </section>
        </div>

    )
}

export default Home;